<template>
  <v-card
    elevation="2"
    color="background"
    class="d-flex flex-column"
    style="height: 100%"
  >
    <v-card-title>
      المحتوى الثابت
      <v-spacer></v-spacer>

      <DefaultDialog
        buttonText="اضافة"
        title="اضافة محتوى"
        :openToEdit="openToEdit"
        editTitle="تعديل محتوى"
        :openToView="openToView"
        viewTitle="عرض محتوى"
        @onCloseDialog="onCloseDialog"
        :toggleSize="true"
        :showAddButton="isCanCreate"
      >
        <PageDetail
          slot-scope="slotProps"
          :submitWithClose="slotProps.submitWithClose"
          :submitWithNew="slotProps.submitWithNew"
          :submitWithoutClose="slotProps.submitWithoutClose"
          :setItemID="setItemID"
          :closeDialog="slotProps.closeDialog"
          :afterSubmit="getPages"
          :itemId="itemId"
          :isEdit="slotProps.isEdit"
          :isView="slotProps.isView"
          :isNew="slotProps.isNew"
        />
      </DefaultDialog>
    </v-card-title>
    <v-card-text
      class="d-flex flex-column justify-space-between"
      style="flex: 1; min-height: 0"
    >
      <!-- Filters -->
      <v-row style="flex: 0">
        <v-col cols="12" class="d-flex">
          <languages-filter @change="changeLanguage" />
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="filters.slug"
            flat
            hide-details="auto"
            label="بحث"
            outlined
            prepend-inner-icon="mdi-magnify"
            solo
            dense
            class="mr-6"
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- Grid -->
      <v-row class="mt-0" style="min-height: 0">
        <v-col cols="12" style="flex: auto; min-height: 0; height: 100%">
          <v-data-table
            class="elevation-1"
            :headers="headers"
            :items="pages"
            :items-per-page="options.pageSize"
            :loading="$store.state.loading.show"
            :ripple="false"
            fixed-header
            hide-default-footer
            height="100%"
            style="height: 100%; overflow: auto"
            loading-text="جار التحميل..."
            no-data-text="لا يوجد"
          >
            <template v-slot:[`item.creationTime`]="{ item }">
              <span dir="ltr">{{
                $service.formatDate(item.creationTime)
              }}</span>
            </template>

            <template v-slot:[`item.controls`]="props">
              <v-btn
                v-if="isCanRead"
                small
                color="#78b200"
                @click="viewRow(props.item)"
                class="mr-1"
              >
                <v-icon color="#fff">mdi-eye</v-icon>
              </v-btn>

              <v-btn
                v-if="isCanUpdate"
                small
                color="#ffa000"
                @click="editRow(props.item)"
                class="mr-1"
              >
                <v-icon color="#fff">mdi-pencil-outline</v-icon>
              </v-btn>

              <v-btn
                v-if="isCanDelete"
                small
                color="pink"
                @click="deleteRow(props.item)"
                class="mr-1"
              >
                <v-icon color="#fff">mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <!-- pagination -->
      <v-row class="mt-0" style="flex: 0">
        <v-col cols="12" class="d-flex justify-space-between align-center">
          <v-pagination
            v-model="options.pageIndex"
            :length="numberOfPages"
            total-visible="7"
          />
          <v-select
            v-model="options.pageSize"
            :items="[5, 10, 15, 20]"
            label="عدد الصفوف"
            solo
            dense
            item-color="white"
            class="flex-grow-0 d-flex align-center"
            hide-details
            style="width: 100px"
          ></v-select>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import qs from 'qs';
import LanguagesFilter from '@/components/LanguagesFilter.vue';
import { selectedLanguage } from '@/config/config';
import DefaultDialog from '@/components/DefaultDialog.vue';
import PageDetail from './crud/PageDetail.vue';
import { mapActions } from 'vuex';
import { showSnackbarFailMessage } from '@/utls/snackbar';
import { getDataFromJSON } from '@/components/BaseEditor.vue';
import { viewHTMLContent } from '@/utls/chkEditor';
import PermissionMixin from '@/mixins/PermissionMixin';
import { appPermissions } from '@/libs/auth/permissions/appPermissions';

const initFilters = {
  language: selectedLanguage,
  slug: '',
};

const initOptions = {
  pageIndex: 1,
  pageSize: 15,
  ...initFilters,
};

export default {
  components: {
    LanguagesFilter,
    PageDetail,
    DefaultDialog,
  },
  mixins: [PermissionMixin(appPermissions.pages)],
  data() {
    return {
      sel: null,
      pages: [],
      total: 0,
      headers: [
        { text: 'id', value: 'id' },
        { text: 'العنوان', value: 'language.title' },
        { text: 'تاريخ الانشاء', value: 'creationTime' },
        { text: '', value: 'controls', sortable: false },
      ],
      options: { ...initOptions },
      filters: { ...initFilters },

      // for view edit and detail
      // ------------------------
      openToEdit: false,
      openToView: false,
      itemId: null,
      // ------------------------
      searchTimer: null,
    };
  },
  computed: {
    skipPerPage() {
      return this.options.pageSize * (this.options.pageIndex - 1);
    },
    numberOfPages() {
      const pages = Math.ceil(this.total / this.options.pageSize) || 1;
      return pages;
    },
  },
  created() {
    this.getPages();
  },
  methods: {
    ...mapActions('confirm', ['openConfirm']),
    deleteRow(item) {
      this.openConfirm({
        message: 'هل انت متأكد من حذف المحتوى ؟',
        confirm: () => {
          return this.$http
            .delete('app/page/' + item.id, {})
            .then(() => {
              this.getPages();
              return true;
            })
            .catch((error) => {
              showSnackbarFailMessage('حدث خطأ اثناء عملية الحذف');
              return false;
            });
        },
      });
    },
    onCloseDialog() {
      this.openToEdit = false;
      this.openToView = false;
    },
    editRow(item) {
      this.openToEdit = true;
      this.itemId = item.id;
    },
    setItemID(id) {
      if (id) return this.editRow({ id });
      this.itemId = null;
      this.onCloseDialog();
    },
    viewRow(item) {
      // this.openToView = true;
      // this.itemId = item.id;
      const languageDtls = item?.pageLanguage[0] || {};
      const title = languageDtls.title;
      const url = '{url}-' + item.slug;
      const editorData = getDataFromJSON(languageDtls.contentJson);
      viewHTMLContent(title, url, editorData);
    },
    changeLanguage(language) {
      this.options.language = language;
    },
    getPages() {
      this.$store.commit('loading/SET_SHOW', true);

      const requestConfig = {
        params: {
          skipCount: this.skipPerPage,
          maxResultCount: this.options.pageSize,
          language: this.options.language,
          slug: this.options.slug,
        },
        paramsSerializer: function (params) {
          const queryString = qs.stringify(params, { arrayFormat: 'brackets' });
          return queryString;
        },
      };
      this.$http
        .get('app/page/by-filters', requestConfig)
        .then((response) => {
          const { items, totalCount } = response.data;

          const resultData = items.reduce((p, c) => {
            const language = c.pageLanguage[0];
            const parentLanguage = c.parent && c.parent.pageLanguage[0];
            const data = [...p, { ...c, language, parentLanguage }];
            return data;
          }, []);

          this.pages = resultData;
          this.total = totalCount;
        })
        .catch((error) => {
          showSnackbarFailMessage('حدث خطأ اثناء عملية جلب البيانات');
        })
        .finally(() => this.$store.commit('loading/SET_SHOW', false));
    },
    searchUser() {},
  },
  watch: {
    options: {
      handler() {
        this.getPages();
      },
      deep: true,
    },
    'filters.slug': {
      handler() {
        clearTimeout(this.searchTimer);
        this.searchTimer = setTimeout(
          () => Object.assign(this.options, this.filters),
          500,
        );
      },
      deep: true,
    },
  },
};
</script>
