<template>
  <form class="fill-height">
    <v-container fluid class="fill-height">
      <v-row class="d-flex flex-row-reverse fill-height">
        <v-col
          cols="12"
          md="3"
          class="flex-grow-1 fill-height"
          :class="{ 'overflow-y-auto': $vuetify.breakpoint.name != 'sm' }"
        >
          <v-row>
            <v-col cols="12" v-if="itemId">
              <AuditLog
                :url="`app/page/${itemId}/changes`"
                class="my-1 justify-end"
                :creationTime="creationTime"
                creationUsername="admin"
                :lastModificationTime="lastModificationTime"
                lastModificationUsername="admin"
                :colsName="{
                  Name: 'الاسم',
                  Slug: 'slug',
                  ImagePath: 'الصورة',
                  ContentJson: 'المحتوى',
                  Title: 'العنوان',
                  Keyword: 'كلمات مفتاحية',
                  Description: 'وصف',
                }"
                :renderSlot="true"
              >
                <template #originalValue="{ item, value }">
                  <span>{{
                    getLogValue(item, value, { isOriginal: true })
                  }}</span>
                </template>
                <template #newValue="{ item, value }">
                  <span>{{
                    getLogValue(item, value, { isOriginal: false })
                  }}</span>
                </template>
              </AuditLog>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <h1>بيانات اساسية</h1>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-row>
                <v-col cols="12">
                  <SlugBase v-model="slug" :foundedSlug="foundedSlug" />
                </v-col>

                <v-col cols="12">
                  <Autocomplete
                    url="app/page/by-filters"
                    :params="{ pageName: '', language: 'en' }"
                    displayProp="slug"
                    label="ضمن محتوى"
                    placeholder="ابحث عن عنوان المحتوى"
                    :value="parent"
                    @onChange="(value) => (parent = value)"
                    renderSlot
                  >
                    <template #render="{ item }">
                      <v-list-item-content>
                        <v-list-item-title v-html="item.slug" color="blue" />
                        <div class="d-inline-flex">
                          <v-list-item-subtitle
                            v-for="(lang, index) in item.pageLanguage"
                            :key="index"
                            v-html="lang.title"
                          />
                        </div>
                      </v-list-item-content>
                    </template>
                  </Autocomplete>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-row class="pa-2">
                <v-col cols="12" dark class="background">
                  <div
                    style="
                      max-height: 400px;
                      max-width: 1000px;
                      margin: auto;
                      border-radius: 4px;
                      overflow: hidden;
                      aspect-ratio: 16/9;
                    "
                  >
                    <v-img
                      v-if="getImageSrc(imagePath, 'sm')"
                      :src="getImageSrc(imagePath, 'lg')"
                      class="fill-height ma-0"
                      contain
                    />
                    <v-row
                      v-else
                      class="background fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-icon large>mdi-image</v-icon>
                    </v-row>
                  </div>
                </v-col>
                <v-col>
                  <UploadImage
                    v-model="imagePath"
                    :multiple="false"
                    text="اختر الصورة"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          md="9"
          class="flex-grow-1 fill-height"
          :class="{ 'overflow-y-auto': $vuetify.breakpoint.name != 'sm' }"
        >
          <v-card elevation="0">
            <!-- TABS HEADER -->
            <v-tabs
              show-arrows
              background-color="indigo"
              dark
              v-model="tab"
              align-with-title
              fixed-tabs
              class="mb-4"
              height="45px"
            >
              <v-tabs-slider color="white darken-1"></v-tabs-slider>
              <v-tab
                v-for="lang in languagesKeysValue"
                :key="lang.key"
                class="white--text font-weight-black"
                active-class="indigo lighten-2"
              >
                {{ lang.value }}
              </v-tab>
            </v-tabs>

            <!-- TABS CONTENT -->
            <v-tabs-items v-model="tab">
              <v-tab-item v-for="lang in languagesKeysValue" :key="lang.key">
                <v-card flat>
                  <v-container fluid>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="formLanguage[`${lang.key}`][`title`]"
                          :counter="100"
                          :label="'العنوان بالـ ' + lang.value"
                          outlined
                          dense
                          hide-details="auto"
                        />
                      </v-col>
                      <v-col cols="6">
                        <v-textarea
                          v-model="formLanguage[`${lang.key}`][`description`]"
                          :counter="200"
                          :label="'الوصف بالـ ' + lang.value"
                          outlined
                          dense
                          hide-details="auto"
                        />
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          v-model="formLanguage[`${lang.key}`][`keyword`]"
                          :counter="50"
                          :label="'كلمات مفتاحية بالـ ' + lang.value"
                          outlined
                          dense
                          hide-details="auto"
                        />
                      </v-col>
                      <v-col cols="12">
                        <FieldCards
                          :title="' المحتوى بالـ ' + lang.value"
                          :ref="`fieldContoller_${lang.key}`"
                          :schema="formLanguage[`${lang.key}`][`contentJson`]"
                          :selectedLanguage="lang.key"
                          :render="true"
                          :canRemove="false"
                          :withSchemaTypes="false"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </form>
</template>

<script>
import Autocomplete from '@/components/Autocomplete.vue';

import { languagesKeys, languagesKeysValue } from '@/config/config';
import { validationMixin } from 'vuelidate';
import { required, maxLength } from 'vuelidate/lib/validators';
import {
  showSnackbarFailMessage,
  showSnackbarSuccessMessage,
  showSnackbarWarningMessage,
} from '@/utls/snackbar';

import UploadImage from '@/components/UploadManager/UploadImage.vue';
import { makeid } from '@/utls/jsCommon';
import { getImageURL } from '@/helper/cmsPaths';
import AuditLog from '@/components/auditLog/AuditLog.vue';
import SlugBase from '@/components/SlugBase.vue';
import FieldCards from '@/components/Fields/FieldCards.vue';
import { getErrorDetails } from '@/helper/commonjs';

const initData = () => ({
  // for languages tab
  tab: null,
  // for languages
  languagesKeysValue: languagesKeysValue(),
  formLanguage: { ...languagesKeys() }, // { ar:{ }, en:{ } }
  // for main info
  slug: '',
  image: null,
  imagePath: '',
  imageRules: [
    (value) =>
      !value ||
      value.size < 1000000 ||
      'حجم الصورة كبير لايمكن ان يتجازو ال 1 ميكا !',
  ],
  parent: null,
  parentId: null,
  creationTime: '',
  lastModificationTime: '',
  foundedSlug: '',
  schemaResults: { invalid: false },
});

export default {
  props: [
    'submitWithClose',
    'submitWithNew',
    'submitWithoutClose',
    'setItemID',
    'afterSubmit',
    'closeDialog',
    'isNew',
    'isView',
    'isEdit',
    'itemId',
  ],
  components: {
    Autocomplete,
    UploadImage,
    AuditLog,
    SlugBase,
    FieldCards,
  },
  mixins: [validationMixin],
  validations: {},
  data: () => ({ ...initData() }),
  async created() {
    if (this.isEdit || this.isView) {
      const responseData = await this.getById(this.itemId);
      if (responseData == null) return; // there is no data - error

      for (const k in this.$data) {
        const value = responseData[k];
        if (value == undefined) continue;
        this.$data[k] = value;
      }

      for (const k in this.$data.formLanguage) {
        // { language: 'ar', .. }, { language: 'en', .. }
        const languageDetail = responseData.pageLanguage.find(
          (x) => x.language == k,
        );
        if (languageDetail == undefined) {
          this.initFormSchema(k);
          continue;
        }
        this.$data.formLanguage[k] = languageDetail;
      }
    } else {
      this.slug = makeid(15);
      for (const k in this.$data.formLanguage) this.initFormSchema(k);
    }
  },
  computed: {
    // validation
    // ----------------
    inputLanguages() {
      const languages = this.languagesKeysValue.reduce((p, v) => {
        const { key, value } = v;

        const languageData = this.formLanguage[key];

        const isEmptyValues = Object.values({
          ...languageData,
          contentJson: '',
        }).every((x) => !x);
        if (isEmptyValues) return p;

        const { title, description, keyword, contentJson } = languageData;
        const schemaResult = this.schemaResults[key];
        const content = (schemaResult || {}).schema || contentJson;

        return [
          ...p,
          { language: key, title, description, keyword, contentJson: content },
        ];
      }, []);
      return languages;
    },
  },
  methods: {
    initFormSchema(k) {
      const initSchema = [
        {
          groupId: 1,
          id: 1,
          component: 'Editor',
        },
        {
          groupId: 1,
          id: 2,
          component: 'Files',
        },
      ];
      this.$data.formLanguage[k]['contentJson'] = JSON.stringify(initSchema);
    },
    buildSchemaResult() {
      const results = { invalid: false };
      Object.keys(this.formLanguage).forEach((key) => {
        const fieldResult = this.getFieldController(key);

        if (!fieldResult || fieldResult?.length == 0) return;

        results[key] = fieldResult.getData();
        if (results[key].invalid) results.invalid = true;
      });
      this.schemaResults = results;
    },
    getLogValue(item, value, { isOriginal }) {
      if (item.propertyName == 'ContentJson') {
        try {
          const content = JSON.parse(JSON.parse(value));
          const { data } = content;
          const isThereValue = !!data;
          return this.getLogFieldValue(isThereValue, isOriginal, 'محتوى');
        } catch (error) {
          return '';
        }
      }
      if (item.propertyName == 'ImagePath') {
        const isThereValue = value.length > 2;
        return this.getLogFieldValue(isThereValue, isOriginal, 'صورة');
      }
      return value;
    },
    getLogFieldValue(isThereValue, isOriginal, label) {
      if (isOriginal) return isThereValue ? label : '';
      return isThereValue ? 'تغيير ' + label : '';
    },
    getImageSrc(name, size) {
      return getImageURL(name, size);
    },
    doSave(postData) {
      this.$store.commit('loading/SET_DIALOG_LOADING', true);
      return this.$http
        .post('app/page', postData)
        .then((response) => {
          this.setItemID(response.data.id);
          this.creationTime = response.data.creationTime;
          this.lastModificationTime = response.data.lastModificationTime;
          if (response.status != 200) return false;
          showSnackbarSuccessMessage('تم الحفظ بنجاح');
          return true;
        })
        .catch(this.errorSaveHabdler)
        .finally(() => this.$store.commit('loading/SET_DIALOG_LOADING', false));
    },
    doUpdate(postData) {
      this.$store.commit('loading/SET_DIALOG_LOADING', true);
      return this.$http
        .put('app/page/' + this.itemId, { ...postData })
        .then((response) => {
          if (response.status != 200) return false;
          showSnackbarSuccessMessage('تم الحفظ بنجاح');
          return true;
        })
        .catch(this.errorSaveHabdler)
        .finally(() => this.$store.commit('loading/SET_DIALOG_LOADING', false));
    },
    errorSaveHabdler(error) {
      const { code, message } = getErrorDetails(error);

      if (code == 'Slug_Found') {
        this.foundedSlug = this.slug;
        return showSnackbarFailMessage(message);
      }

      return showSnackbarFailMessage(message ?? 'حدث خطأ اثناء عملية الحفظ');
    },
    clearInput() {
      const _initData = initData();
      for (const k in this.$data) this.$data[k] = _initData[k];
      this.slug = makeid(15);

      // reset schema
      for (const k in this.$data.formLanguage) this.initFormSchema(k);

      Object.keys(this.formLanguage).forEach((key) => {
        const field = this.getFieldController(key);
        field?.reset();
      });
    },
    getFieldController(key) {
      const fieldResult = this.$refs[`fieldContoller_${key}`];
      if (!fieldResult || fieldResult?.length == 0) return;
      return fieldResult[0];
    },
    isInvalid() {
      this.$v.$touch();
      if (this.$v.$invalid) return true;
      return false;
    },
    async getById(id) {
      this.$store.commit('loading/SET_DIALOG_LOADING', true);
      return this.$http
        .get('app/page/' + id, { params: { language: 'all' } })
        .then((response) => {
          const { data } = response;
          return data;
        })
        .catch((error) => {
          showSnackbarFailMessage('حدث خطأ اثناء عملية جلب البيانات');
          this.closeDialog(false, true);
          return null;
        })
        .finally(() => this.$store.commit('loading/SET_DIALOG_LOADING', false));
    },
    doSubmit(cb) {
      this.buildSchemaResult();

      if (this.isInvalid() || this.schemaResults.invalid) {
        showSnackbarWarningMessage('يجب عدم ترك الاعمدة المطلوبة فارغة');
        return;
      }

      const parentId = (this.parent && this.parent.id) || null;
      const slug = this.slug;
      const imagePath = this.imagePath;
      const languages = this.inputLanguages;

      const postData = {
        parentId,
        slug,
        imagePath,
        pageLanguage: languages,
      };

      if (this.isNew) {
        this.doSave(postData).then((status) => {
          if (!status) return;
          cb?.();
        });
      } else {
        // TODO : AH Need Refactor

        if (this.itemId == parentId) {
          showSnackbarWarningMessage('قيمة ضمن المحتوى غير مقبولة');
          return;
        }

        this.doUpdate(postData).then((status) => {
          if (!status) return;
          cb?.();
        });
      }
    },
  },
  watch: {
    submitWithClose() {
      this.doSubmit(() => {
        this.clearInput();
        this.afterSubmit();
        this.closeDialog(true);
      });
    },
    submitWithNew() {
      this.doSubmit(() => {
        this.clearInput();
        this.afterSubmit();
        this.setItemID(null);
      });
    },
    submitWithoutClose() {
      this.doSubmit(() => {
        this.afterSubmit();
      });
    },
  },
};
</script>

<style lang="scss">
.link-input {
  input {
    direction: ltr;
    text-align: left;
    unicode-bidi: bidi-override;
  }
}
</style>
